@import "variables";

.k-grid {
    border-radius: 0px;

    td{
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 1.2em;
    }

    .k-button {
        padding: 0px;
        line-height: 1.2em;
    }
}

.kendo-grid-show-btn {
    min-width: 30px !important;
    min-height: 30px !important;

    text-align: center;
    padding: 7px 0 !important;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;

    background-color: $table-show-btn-background-color !important;
    color: $table-show-btn-font-color !important;

    border: transparent !important;
    box-shadow: none !important;
}

.kendo-grid-download-btn {
    min-width: 30px !important;
    min-height: 30px !important;

    text-align: center;
    padding: 7px 0 !important;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;

    background-color: $table-download-btn-background-color !important;
    color: $table-download-btn-font-color !important;

    border: transparent !important;
    box-shadow: none !important;
}

.company-editing-dialog,
.combination-editing-dialog {
    width: 600px;

    .primary-address {
        text-align: center;
        font-weight: bold;
    }

    .form-group {
        .alert {
            margin-bottom: 0px;
        }
    }

    .form-horizontal .primary-address {
        margin-left: 0px;
        margin-right: 0px;
    }
}

.company-editing-dialog .edit-button-container,
.combination-editing-dialog .edit-button-container {
    margin-bottom: 16px;
    text-align: center;
}

.company-editing-dialog .edit-button-container button,
.combination-editing-dialog .edit-button-container button {
    width: 200px;
}

.company-editing-dialog .label-container,
.combination-editing-dialog .label-container {
    text-align: right;
    min-height: 25px;
}

.login {
    margin-top: 50px;
    animation: fadeIn 2s;

    .logo {
        background-image: url('../img/combined-logo.png');
        background-position: center center;
        background-repeat: no-repeat;
        height: 128px;
        margin-bottom: 24px;
    }
}

.grid-loading-indicator {
    opacity: 0.7;
}

.grid-view-footer {
    button, .button-group {
        border-top-width: 0px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;

        margin-left: 2px;

        &:first-of-type {
            margin-left: 0px;
        }
    }

    button:focus,
    button:active,
    button:active:focus {
        outline: none;
        outline-offset: 0px;
    }

    .dropdown-button {
        margin-top: 2px;
        padding: 5px 12px;
    }

    .dropdown-menu-right {
        left: none;
        right: 0;
    }

    .right-buttons {
        display: inline-block;
        float: right;
    }

    .grid-footer-checkbox {
        display: inline-block;
        padding-top: 6px;
        padding-right: 12px;
    }
}

.distributor-checkbox {
    padding-top: 4px !important;
    width: 50%;
    display: inline-block;
    vertical-align: top;
}

.contact-own-adress {
    margin-left: 50px;
}


.contributions-view {
    .row {
        margin-top: 15px;
    }

    .center-cell {
        text-align: center;
    }

    .money-cell {
        text-align: right;
        padding-left: 0px;
        padding-right: 0px;
    }

    .text-cell {
        padding-left: 0px;
        padding-right: 0px;
    }

    .sum-row {
        font-weight: bold;
        border-top: 2px solid black;
    }

    .table {
        margin-top: 20px
    }
}


.grid-col-number {
	text-align: right;
}

.grid-col-boolean {
	text-align: center;
}

.daily-papers-share-amount {
    padding: 6px;
    text-align: right;
}

.daily-papers-share-amount-error {
    color: red;
}

.k-alt {
    background-color: $table-odd-color;

    &.k-state-selected {
        background-color: $table-selected-color;
    }
}

.k-grid tr:hover {
    background-color: $table-hover-color;
}

* {
    font-family: Arial,Verdana,sans-serif;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.splash-app-name {
    color: $ma-blue;
    font-size: 42px;
    line-height: 42px;
}

.grid-filter {
    margin-top: 15px;
    margin-right: 10px;
}

.reset-padding {
    padding: 0px !important;
}

.test-invoice-button {
    margin-right: 10px;
}

.switch-checkbox-label {
    padding-top: 0px !important;
}

.full-width {
    width: 100% !important;
}

.mt-1 {
    margin-top: 1em;
}

.mt-2 {
    margin-top: 2em;
}

.template-selection-label {
    margin-top: 10px;
    font-weight: normal;
}

.invoice-amount-different {
    color: $table-invoice-amount-different-font-color !important;
}


.dropdown-submenu {
    position:relative;
}

.dropdown-submenu > .dropdown-menu {
    top:0;
    left:auto !important;
    right:100% !important;
    margin-top:-6px;
    margin-left:-1px;
    -webkit-border-radius:0 6px 6px 6px;
    -moz-border-radius:0 6px 6px 6px;
    border-radius:0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu{
    display:block;
}

.dropdown-submenu > a:after {
    display:block;
    content:" ";
    float:right;
    width:0;
    height:0;
    border-color:transparent;
    border-style:solid;
    border-width:5px 0 5px 5px;
    border-left-color:#cccccc;
    margin-top:5px;
    margin-right:-10px;
}

.dropdown-submenu:hover > a:after {
    border-left-color:#ffffff;
}

.dropdown-submenu.pull-left {
    float:none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
    left:-100%;
    margin-left:10px;
    -webkit-border-radius:6px 0 6px 6px;
    -moz-border-radius:6px 0 6px 6px;
    border-radius:6px 0 6px 6px;
}



.dashboard-container {
    background-color: #E8EAEC;

    margin-top: 100px;
    padding-top: 20px;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 20px;

    .dashboard-button-group {
        width: 100% !important;
    }

    .dashboard-button {
        width: 100% !important;
        min-height: 100px !important;
        margin: 5px !important;
        font-size: 24px;

        border-top: 5px solid #012e55;
    }

    .dashboard-button:focus,
    .dashboard-button:hover,
    .dashboard-button:active,
    .dashboard-button:active:focus {
        border-top: 5px solid #012e55;
    }


    .dropdown-submenu > .dropdown-menu {
        top:0;
        left:100% !important;
        right: auto !important;
        margin-top:-6px;
        margin-left:-1px;
        -webkit-border-radius:0 6px 6px 6px;
        -moz-border-radius:0 6px 6px 6px;
        border-radius:0 6px 6px 6px;
    }
}
