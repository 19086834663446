$ma-blue: #162D53;

$navbar-height: 51px;
$navbar-color: #FFFFFF;
$navbar-font-color: #002e54;
$navbar-active-font-color: #0070b8;

$navbar-active-dropdown-font-color: $navbar-active-font-color;
$navbar-active-dropdown-background-color: #e7e7e7;

$contacts-main-margin: 15px;

$contact-button-bar-height: 54px;
$button-bar-height: 34px;

$contribution-title-height: 46px;
$contribution-bottom-bar: 33px;
$contribution-table-height-offset: $contribution-title-height + $contribution-bottom-bar + $contact-button-bar-height;

$table-selected-color: #0070b8;
$table-odd-color: #EBEBEB;
$table-hover-color: darken($table-odd-color, 10%);

$table-header-background-color: #0070b8;
$table-header-border-color: darken($table-header-background-color, 5%);
$table-header-font-color: #FFFFFF;

$table-show-btn-background-color: #2196F3;
$table-show-btn-font-color: #FFFFFF;

$table-download-btn-background-color: #5cb85c;
$table-download-btn-font-color: #FFFFFF;

$table-invoice-amount-different-font-color: #d75452;
